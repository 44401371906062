<template>
  <div class="project-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="contract/maintenance-contracts/page"
      @selection-change="handleSelectionChange">
      <el-button
        v-if="$auth('合同管理新增')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.editPage.open(0)">
        {{$l("common.add", "新增")}}
      </el-button>


      <el-button
        v-if="$auth('合同管理导入')"
        slot="toolbar"
        :loading="loading"
        type="primary"
        size="mini"
        @click="visible=!visible">
        导入
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('project.contractCode','合同编号')">
            <el-input v-model.trim="filter.contractCode" clearable></el-input>
          </vm-search>
          <vm-search label="客户名称">
            <el-input v-model.trim="filter.customerCompanyName" clearable></el-input>
          </vm-search>

          <vm-search label="合同状态">
            <vm-dict-select v-model="filter.status" type="projectStatus"></vm-dict-select>
          </vm-search>
          <vm-search label="维保类型">
            <el-select v-model="filter.type" clearable placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </vm-search>
          <!-- <vm-search label="开始时间">
            <el-date-picker
              v-model="filter.startTime"
              style="width: 185px"
              type="date"
              value-format="yyyy-MM-dd 00:00:00">
            </el-date-picker>
          </vm-search>
          <vm-search label="结束时间">
            <el-date-picker
              v-model="filter.endTime"
              style="width: 185px"
              type="date"
              value-format="yyyy-MM-dd 23:59:59">
            </el-date-picker>
          </vm-search> -->
          <vm-search label="时间范围">
            <el-date-picker
              v-model="filter.seTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="handleTime">
            </el-date-picker>
          </vm-search>
        </div>
      </template>
      <div slot="tree" :style="{width:width+'px'}" class="treeBox">
        <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
      </div>
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column prop="contractCode" :label="$l('project.name','合同名称')"></el-table-column>

      <el-table-column prop="customerCompanyName" label="客户公司名称"></el-table-column>
      <el-table-column prop="realEstateName" label="楼盘名称">
        <template slot-scope="scope">
          {{scope.row.realEstates instanceof Array ? scope.row.realEstates.map(item=>{
            if (item.realEstateName){
              return item.realEstateName.replace('[','')

            }else {
              return ''
            }
          }):''}}
        </template>
      </el-table-column>
      <el-table-column prop="signTime" label="签订日期">
        <template slot-scope="scope">
          {{scope.row.signTime?$moment(scope.row.signTime).format('YYYY-MM-DD'):''}}
        </template>
      </el-table-column>
      <el-table-column prop="elevatorQuantity" label="维保电梯台量"></el-table-column>
      <el-table-column prop="startTime" label="开始时间">
        <template slot-scope="scope">
          {{scope.row.startTime?$moment(scope.row.startTime).format('YYYY-MM-DD'):''}}
        </template>
      </el-table-column>
      <el-table-column prop="endTime" label="结束时间">
        <template slot-scope="scope">
          {{scope.row.endTime?$moment(scope.row.endTime).format('YYYY-MM-DD'):''}}
        </template>
      </el-table-column>

      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button v-if="$auth('合同管理编辑')" type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>
          <el-button v-if="$auth('合同管理删除')" type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <el-dialog :visible.sync="visible" width="800px">
      <vm-drag-upload-file
        url="excelTemplate/contract.xlsx"
        action="contract/maintenance-contract/import"
        name="维保合同导入"
        download-name="维保合同.xlsx"
        @save-success="getList(-1)"></vm-drag-upload-file>
      <vm-drag-upload-file
        url="excelTemplate/contractItem.xlsx"
        action="contract/maintenance-contract/item-import"
        name="维保合同明细导入"
        download-name="维保合同明细.xlsx"
        @save-success="getList(-1)"></vm-drag-upload-file>
    </el-dialog>
  </div>
</template>
<script>
  import EditPage from "./ContractEdit.vue";
  import {getWidth} from "@/util";
  export default {
    components: {EditPage},
    data() {
      return {
        loading:false,
        filter: {
          name: "",
          contractCode: "",
          customerName: "",
          wbManagerName: "",
          status: "",
          customerCompanyName:"",
          startTime: "",
          endTime: "",
          seTime: "",
        },

        options: [{
          value: "regulation",
          label: "按规维保",
        }, {
          value: "as_need",
          label: "按需维保",
        }],
        selectList:[],
        data:[],
        width:200,
        visible:false,
      };
    },
    mounted() {
      this.getList(1);
      this.getTreeList();

    },
    methods: {
      handleTime() {
        console.log(this.filter.seTime);
        if(this.filter.seTime !=null) {
          this.filter.startTime = this.filter.seTime[0];
          this.filter.endTime = this.filter.seTime[1];
        }
      },
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
        this.visible=false;
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.contractCode}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete("contract/maintenance-contract/"+row.id).then(() => {
            this.getList(-1);
            this.$message.success(row.name + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
      handleSelectionChange(val) {
        this.selectList = val;
      },
      getTreeList() {
        let filter = this.filter;
        if (this.filterFun) {
          let filterCopy = JSON.parse(JSON.stringify(filter));
          filter = this.filterFun(filterCopy);
        }
        let http =  this.$http.get("/basic/district/tree/locate-and-with-customer-company");
        http.then(data => {
          this.data = data;
          this.width=100+14*getWidth(data);
        }).catch(() => {
          this.status = "error";
        });
      },
      handleNodeClick(e) {
        if (e.other==="customerCompany") {
          this.filter.customerCompanyName=e.label;
          this.getList(-1);
        }

      },

    },
  };
</script>
<style lang="scss" scoped>
.treeBox{
  border: 1px solid #E6E6E6;
  padding: 10px;
  margin-right: 10px;
  width: calc(100% - 1300px);
  max-height: 750px;
  overflow-y: auto;
}

</style>
